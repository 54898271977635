import Tables from "../../Components/Tables/Table"
import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import Button from "../../Components/Buttons/Button"
import { useNavigate } from "react-router-dom"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"
import Swal from 'sweetalert2'


async function getData(endpoint) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
      .then(data => data.json())
   }

  
const columns = [
    // {
    //     name: "Image",
    //     selector: row => row.image,
    //     sortable:true
       
    // },
    {
        name: "Firstname",
        selector: row => row.firstname,
        sortable:true
       
    },

 
    {
        name: 'Lastname',
        selector: row => row.lastname,
        sortable:true
        
    },
    {
        name: 'City/State/Region',
        selector: row => row.city_state_region,
        sortable:true,
        
     
    },
    {
       
        name: 'Phone',
         selector: row => row.phone,
        sortable:true,
    Width:200
    
       
    
    },

    {
        name: 'Postion',
        selector: row => row.position,
        sortable:true,
        
     
    },
    {
       
        name: 'Type',
         selector: row => row.type,
        sortable:true,
    Width:200
    
       
    
    },
     {
       
        name: 'Application Date',
         selector: row => row.created_at,
        sortable:true,
    Width:200
    
       
    
    },
    // {
    //     name: 'status',
    //     selector: row => row.status,
    //     sortable:true,
     
    // },
    // {
    //     name: 'age',
    //     selector: row => row.age,
    //     sortable:true,
     
    // },
    // {
    //     name: 'SMS',
    //     selector: row => row.sms,
    //     sortable:true,
     
    
    // },
    {
        name: 'Application',
        selector: row => row.application,
        sortable:true,
     
    
    },
    {
        name: 'CV',
        selector: row => row.cv,
        sortable:true,
     
    
    }
  
];

export default function Contestants(){
    <Protected/>

    const [tableData,setTableData]= useState([]);
    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [search, setSearch]=useState('');
    const navigate = useNavigate();
    const role =sessionStorage.getItem('role');
    
  
    useEffect(()=>{
    async function Loadallusers(){ 
        await getData('applicants').then((response) => { 
         console.log(response.data);
            setPending(true);
            PopulateTable(response.data);
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
       })}
       Loadallusers(); 
      
},[]);

const handleSearch=async()=>{
    try {

        setPending(true)
        let newTable = await allTableData.filter(row=>{
         //   console.log('search',row.firstname.includes(search))
            return row.type.toLowerCase().includes(search.toLowerCase())||row.reference.toLowerCase().includes(search.toLowerCase())||row.phone.toLowerCase().includes(search.toLowerCase());
        })
        PopulateTable(newTable)
        setPending(false)

    } catch (error) {
        console.log(error)
    }
}



function PopulateTable(newTable)
{
   
    let searchData = newTable.map(table=>{

        let Url=process.env.REACT_APP_BASE_URL;
        let cvFile="";
        let applicationFile="";
        let Action="";
       Url= Url.substring(0, Url.length-5);
     
            cvFile =Url.concat(table.cv);
            applicationFile =Url.concat(table.application);
     
    // console.log(applicationFile)

let Cv=          <a href = {cvFile} target = "_blank"> <button className={styles.contestant_details}   ><i class="fa fa-file-pdf-o" Style="font-size:30px; color:green" aria-hidden="true"></i></button></a>
let Application=    <a href = { applicationFile} target = "_blank"> <button className={styles.contestant_details}   ><i class="fa fa-file-pdf-o" Style="font-size:30px; color:blue" aria-hidden="true"></i></button></a>

if(role==="admin")
{
  

   }
else{

}

        return {
        //   image:<div><img height="40" width="40" src={profile} alt="profile"/></div>,
         

            firstname:table.firstname,
            lastname:table.lastname,
            city_state_region:table.state_city_region,
            position:table.position,
            type:table.type,
            phone:table.phone,
            created_at:table.created_at,
            // age:table.contestant_details.age?table.contestant_details.age:"-",
            // contestant_details:details,
            // contestant_details:details,
            // status:(table.active==1)?<span style={{color:"green"}}>Active</span>:"Disqualified",
            // sms:(table.sms_sent==1)?<span style={{color:"green"}}>Sent</span>:<span style={{color:"red"}}>Not Sent</span>,
        
            application:Application,
            cv:Cv
           
                                 
        }
    })
    setTableData(searchData);
}
 useEffect(()=>{
        handleSearch();
    },[search])
  useEffect(()=>{
        handleSearch();
    },[search])
function handleClick(){
    navigate('/addcontestant',{state:{new:true}});
}
function onClick(rowdata,profile)
{
  
navigate('/addcontestant',{state:{data:rowdata, profile:profile, new:false}});
}
function Details(details)
{
    Swal.fire({
      
        html: JSON.stringify(details),
     
      })

}
function onDelete(rowdata, profile)
{

    navigate('/deletecontestant',{state:{data:rowdata, profile:profile, new:false}});
}
function CMS(rowdata, profile)
{

    navigate('/contestant_cms',{state:{data:rowdata, profile:profile, new:false}});
}

let add_user_button="";
if(role==="admin")
{
add_user_button=<> 
<Button onClick={handleClick} height={30} width={120} background={"rgb(155, 101, 96)"} color={"white"} value={"Add Contestant"}/>
</>
}
    return (
        <div className={styles.maincontent}>
          
  <TitleRow>Applicants</TitleRow>
<Row height={20}>
  <div className={styles.actionbox}>
  <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} style={{background:"transparent"}}/>
  {/* {add_user_button} */}
  </div></Row>
      <Row>{pending?"Loading...":<Tables  progressPending={pending} data={tableData} columns={columns} paginate={true}/>}</Row>
        </div>
    )
}