import styles from "./rows.module.css"

export default function TitleRow(props){
    const height=props.height?props.height:null;
   const  width=props.width?props.width:null;
    return(
        <div className={styles.titlerow} style={{height:height, width:width}}>
            {props.children}
        </div>
    )
}