
import InputForm from "../Components/Form/inputForm"
import styles from "./pages.module.css"
import Electricity from "../Components/electricity"
import Img1 from "../Resources/images/image1.jpg"


import Logo from "../Resources/images/logo.png"
import { useNavigate} from "react-router-dom"
import swal from 'sweetalert';
import { useEffect, useState } from "react"
import Slider from "../Components/Slider"
import { Lines } from 'react-preloaders';


async function loginUser(credentials) {

  
  return fetch(`${process.env.REACT_APP_BASE_URL}login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }



export default function Login(){

  const [image, setImage]=useState(Img1);
  const [count, setCount]=useState(1);
  const [loading, setLoading] = useState(false);

const navigate=useNavigate();
  async function Onsubmit(credential, e){
    e.preventDefault();  
    setLoading(true);
    const response = await loginUser(credential);
  
   if (response.data) {
    setLoading(false)
      swal("Success","User Logged in", "success", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        sessionStorage.setItem('access_token', response.data.token);
        sessionStorage.setItem('username',response.data.user.firstname+" "+response.data.user.lastname);
        sessionStorage.setItem('userid', response.data.user.id);
        // sessionStorage.setItem('role', response.data.user.role);
      //  console.log(sessionStorage.getItem('username'));


       navigate('/dashboard');
        window.location.reload();
      });
    } else {
      setLoading(false)
      swal("Failed", JSON.stringify(response.errors[0]), "error");
    }
    // response.errors &&  swal("Failed", JSON.stringify(response.errors[0]), "error");
  }


    const data=[
        {
          input:{
                  type:"email", placeholder:"Enter Email",name:"email", value:"",
                  validation:{required:true,  maxLength:50, pattern:/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/}
                }
        },
        {
          input:{
                  type:"password",name:"password", value:"", placeholder:"Enter Password",
                  validation:{required:true,  maxLength:50 }
                }
        },   
        {
          submit:{
                  type:"submit", value:loading?"Loading...":"Login"
                }
        }
      ]
      return(
        <div className={styles.loginpage}>
          <div className={styles.loginlefthalf}>
          <div className={styles.loginImageCaption}>
          <div className={styles.loginLogo}>
            <img src={Logo} className={styles.loginLogo} alt="logo"/>
            </div>
            <span className={styles.loginImageCaption_1}></span>
            <span className={styles.loginImageCaption_2}>JOINT HEALTH CARE BUSINESS</span>
            <span className={styles.loginImageCaption_3}>DASHBOARD</span>
          </div>
            <div className={styles.loginImageBox}>
            
          <img  className={styles.loginImage} src={Img1} alt="JOINT HEALTH CARE"/>
          </div>
          <div className={styles.loginImageCaption}>
          <span className={styles.loginImageCaption_3}><i>6430 Evening Rose Lane</i></span>
<span className={styles.loginImageCaption_3}><i>Katy, Texas 77449</i></span>
<br/>
<span><i>Phone: 713-592-8955</i></span>
<span><i>Fax: 713-592-8978</i></span>
<span><i>Email: jointhealthcare@yahoo.com</i></span>
    
             </div>
          
    </div>
    <div className={styles.loginrighthalf}>
    <div className={styles.loginBox}>
    <InputForm  data={data} onSubmit={Onsubmit} logo={Logo } />
    
    </div>
    </div>

    </div>
  
    
      )
      
}
