import Tables from "../../Components/Tables/Table"
import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import Button from "../../Components/Buttons/Button"
import { useNavigate } from "react-router-dom"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"
import Swal from 'sweetalert2'
import axios from 'axios'



   async function getData(userData, endpoint) {

    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
      body: JSON.stringify(userData)
    })
      .then(data => data.json())
   }
  
  
const columns = [
    // {
    //     name: "Image",
    //     selector: row => row.image,
    //     sortable:true
       
    // },
    {
        name: "Code",
        selector: row => row.code,
        sortable:true
       
    },

 
    {
        name: 'name',
        selector: row => row.name,
        sortable:true
        
    },
    // {
    //     name: 'status',
    //     selector: row => row.status,
    //     sortable:true,
        
     
    // },

    {
        name: 'vote',
        selector: row => row.vote,
        sortable:true,
    },
   
  
];

export default function Votes(){
    <Protected/>

    const [tableData,setTableData]= useState([]);
    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [name, setName]=useState('');
    const [startAge, setStartAge]=useState('');
    const [endAge, setEndAge]=useState('');
    const [count, setCount]=useState('0');
    const [search, setSearch]=useState('');
    const navigate = useNavigate();
    const role =sessionStorage.getItem('role');
    const [startDate, setStartDate]=useState('');
    const [endDate, setEndDate]=useState('');
    
  
    useEffect(()=>{
    async function Loadallusers(){ 
        await getData(null, 'contestant-votes').then((response) => { 


           
            setPending(true);
            PopulateTable(response.data);
         
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
       })}
       Loadallusers(); 
      
},[]);

var DataInput={'startDate':startDate, 'endDate':endDate}
const handleFilter=async()=>{

    await getData(DataInput, 'contestant-votes').then((response) => { 
        setPending(true);
        PopulateTable(response.data.contestants);
        setCount(response.data.count);
        setPending(false);
        setAllTableData(response.data.contestants);
        response.errors && console.log(response.errors);
   })
}
const handleExcelExport=async()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}generate-vote-excel?startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
        headers: {
            //   'Content-Type': 'application/json',
            //    'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
             },
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
    });
}
const handlePDFExport=async()=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}get-single-contestant-pdf?startDate=${startDate}&endDate=${endDate}`, {
        method: 'GET',
        headers: {
            //   'Content-Type': 'application/json',
            //    'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
             },
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.pdf`);
        document.body.appendChild(link);
        link.click();
    });
}

const handleSearch=async()=>{
    try {

        setPending(true)
        let newTable = await allTableData.filter(row=>{
         //   console.log('search',row.firstname.includes(search))
            return row.code.toLowerCase().includes(search.toLowerCase())||
            row.name.toLowerCase().includes(search.toLowerCase())
            // row.vote.toLowerCase().includes(search.toLowerCase())
            // row.contestant_details.name.toLowerCase().includes(search.toLowerCase())||
            // row.contestant_details.age.toLowerCase().includes(search.toLowerCase());
        })
        PopulateTable(newTable)
        setPending(false)

    } catch (error) {
        console.log(error)
    }
}



function PopulateTable(newTable)
{
   
    let searchData = newTable.map(table=>{

        let imageUrl=process.env.REACT_APP_BASE_URL;
        let profile="";
        let Action="";
       imageUrl= imageUrl.substring(0, imageUrl.length-4);
        if(table.profile)
        {
         profile =imageUrl.concat(table.profile.substring(1));
  
//alert(profile);
}
let details=""
details= <> <button className={styles.contestant_details} onClick={() => Details(table.contestant_details)}  >{JSON.stringify(table.contestant_details)}</button></>

Action=<>   <button style={{color:"white",background:"#6b98ae",   boxShadow: "1px 1px 2px 2px #d0cdcd", border:"none", cursor:"pointer", fontSize:14, padding:8}} onClick={() => onClick(table, profile)} > Register
</button>

{/* <button style={{color:"red",background:"white", border:"none", cursor:"pointer"}} onClick={() => onDelete(table, profile)} > Delete
</button> */}

{/* <button style={{color:"blue",background:"white", border:"none", cursor:"pointer"}} onClick={() => CMS(table, profile)} > CMS */}
{/* </button> */}
</>
if(role==="admin")
{
  

   }
else{

}

        return {
        //   image:<div><img height="40" width="40" src={profile} alt="profile"/></div>,
         

            code:table.code,
            
            name:table.name,
            status:(table.active==1)?<span style={{color:"green"}}>Active</span>:"Disqualified",
            vote:<span style={{color:"blue"}}><b>{table.vote_sum_vote??0}</b></span>,
           
           
                                 
        }
    })
    setTableData(searchData);
}
 useEffect(()=>{

         handleSearch();
    },[search])
//   useEffect(()=>{
//         handleSearch();
//     },[search])
function handleClick(){
    navigate('/addcontestant',{state:{new:true}});
}
function onClick(rowdata,profile)
{
  
navigate('/addcontestant',{state:{data:rowdata, profile:profile, new:false}});
}
function Details(details)
{
    Swal.fire({
      
        html: JSON.stringify(details),
     
      })

}
function onDelete(rowdata, profile)
{

    navigate('/deletecontestant',{state:{data:rowdata, profile:profile, new:false}});
}
function CMS(rowdata, profile)
{

    navigate('/contestant_cms',{state:{data:rowdata, profile:profile, new:false}});
}

let add_user_button="";
if(role==="admin")
{
add_user_button=<> 
<Button onClick={handleClick} height={30} width={120} background={"rgb(155, 101, 96)"} color={"white"} value={"Add Contestant"}/>
</>
}
    return (
        <div className={styles.maincontent}>
          
  <TitleRow> Votes</TitleRow>
<Row height={20}>
  <div className={styles.filter}>
  {/* <span style={{color:"rgb(155, 101, 96)"}} >Search  </span> */}

  <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} style={{background:"transparent"}}/>
  {/* <input type="number" placeholder="start age"  value={startAge} onChange={(e)=>setStartAge(e.target.value)}/>
 <input type="number" placeholder="end age" value={endAge} onChange={(e)=>setEndAge(e.target.value)}/>
 <input type="text" placeholder="Name"  value={name} onChange={(e)=>setName(e.target.value)}/> */}
  {/* <button className={styles.search}  onClick={handleFilter} >Filter</button> */}
  <div>
  {/* <span style={{color:"rgb(155, 101, 96)"}} >Filter from:  </span> */}
 {/* <input type="date" placeholder="start date"  value={startDate} onChange={(e)=>setStartDate(e.target.value)}/> */}
 {/* <span style={{color:"rgb(155, 101, 96)"}} > to:  </span> */}
 {/* <input type="date" placeholder="end date" value={endDate} onChange={(e)=>setEndDate(e.target.value)}/> */}
 {/* <input type="text" placeholder="Name"  value={name} onChange={(e)=>setName(e.target.value)}/> */}
  {/* <button className={styles.search}  onClick={handleFilter} >Filter</button> */}
  </div>
  <div>
  <button className={styles.export_excel} onClick={handleExcelExport} >Excel</button>
  {/* <button className={styles.export_pdf}  onClick={handlePDFExport}>pdf</button> */}
  {/* <span style={{color:"rgb(155, 101, 96)"}}>   Count: {count} </span> */}
  </div>
  </div></Row>
<Row></Row>
      <Row>{pending?"Loading...":<Tables  progressPending={pending} data={tableData} columns={columns} paginate={true}/>}</Row>
        </div>
    )
}