import styles from "../pages.module.css"

import Row from "../../Components/RowDivs/Row"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Graphs from "../../Components/Graph/Graph"
import Tables from "../../Components/Tables/Table"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"

async function getData(endpoint) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
      .then(data => data.json())
   }
const columns = [
    {
        name: 'Image',
        selector: row => row.image,
        sortable:true,
        headerStyle:{background:"green"},
        // style: {
        //     background: "orange",
        //   },
    },
    {
        name: "Code",
        selector: row => row.code,
        sortable:true
    },
    {
        name: "Contestant's Name",
        selector: row => row.name,
        sortable:true
    },
    {
        name: "Nickname",
        selector: row => row.nickname,
        sortable:true
    },
    {
        name: 'Votes',
        selector: row => row.votes,
        sortable:true
    }
    ,
    {
        name: "Voter's contact",
        selector: row => row.voter_contact,
        sortable:true
    }
    ,
    {
        name: 'Date',
        selector: row => row.date,
        sortable:true
    }
    // {
    //     cell: () => <button onClick={(e)=>alert(e.target)}>Action</button>,
    //     ignoreRowClick: false,
    //     allowOverflow: true,
    //     button: false
    // }
];

export default function VoteHistory(){
    const [tableData,setTableData]= useState([]);
    const {topvoters, setTopVoters}=useState([]);

    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [search, setSearch]=useState('');
    <Protected/>

        const [summarydata, setSummarydata]=useState([]);
        useEffect(()=>{
            async function Loadvotesummary(){ 
         await getData('votesummary').then((response) => { 
        response.data?setSummarydata(response.data):console.log(response.errors);
        })}
        async function Loadallvotes(){ 
            await getData('allvotes').then((response) => { 
                //console.log(response);
                let top_voters = response.data.map(table=>{
                    return {
                        contact:table.voter_contact,
                        vote: table.votes,
                             
                     }
                })
            setPending(true);
           // setTopVoters(top_voters);
            PopulateTable(response.data);
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
           })}
           Loadallvotes();
        //     Loadvotesummary();
        //     const interval=setInterval(()=>{ 
        //     Loadvotesummary();
        //     Loadallvotes();
           
        //     },10000);
        //     return ()=>{
        //       clearInterval(interval)
        //     }
           
    },[]);
    const handleSearch=async()=>{
        try {
            setPending(true)
            let newTable = await allTableData.filter(row=>{
             //   console.log('search',row.firstname.includes(search))
                return row.firstname.toLowerCase().includes(search.toLowerCase())||row.nickname.toLowerCase().includes(search.toLowerCase());
            })
            PopulateTable(newTable)
            setPending(false)
    
        } catch (error) {
            console.log(error)
        }
    }
    function PopulateTable(newTable)
{
    let searchData = newTable.map(table=>{
        let imageUrl="";
        if(table.profile)
        {
 imageUrl =process.env.REACT_APP_BASE_URL.split("api")[0].concat(table.profile.substring(1));
        }
        return {
            image:<div><img height="40" width="40" src={imageUrl} alt="profile"/></div>,
       
            code:table.contestant.code,
            name: table.contestant.firstname,
            nickname:table.contestant.nickname,
            votes:table.votes,
            voter_contact:table.voter_contact,
            date:table.created_at.split('T')[0]

         }
    })
    setTableData(searchData);
}
 useEffect(()=>{
        handleSearch();
    },[search])
 
    return (
        <div className={styles.maincontent}>
            <TitleRow>Votes History</TitleRow>
        <Row height={20}>
  <div className={styles.actionbox}>
  <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} style={{background:"transparent"}}/>
 </div></Row>
 <Row background={"white"}> <Tables data={tableData} columns={columns} paginate={true} /></Row>
          
        </div>
    )
}
