import styles from "../pages.module.css"

import Row from "../../Components/RowDivs/Row"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Graphs from "../../Components/Graph/Graph"
import Tables from "../../Components/Tables/Table"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"
import Button from "../../Components/Buttons/Button"
import axios from "axios"

async function getData(endpoint) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
     //   'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
    //  .then(data => data.json())
   }
const columns = [
    {
        name: 'Image',
        selector: row => row.image,
        sortable:true,
        headerStyle:{fontWeight:"bold",'whiteSpace': 'unset'},
     
    },
    {
        name: "Code",
        selector: row => row.code,
        sortable:true
    },
    {
        name: "Contestant's Name",
        selector: row => row.name,
        sortable:true
    },
    {
        name: "Nickname",
        selector: row => row.nickname,
        sortable:true
    },
    {
        name: 'Votes',
        selector: row => row.votes,
        sortable:true
    }
    // {
    //     cell: () => <button onClick={(e)=>alert(e.target)}>Action</button>,
    //     ignoreRowClick: false,
    //     allowOverflow: true,
    //     button: false
    // }
];

export default function Export(){
    const [tableData,setTableData]= useState([]);
    const [startDate, setStartDate]=useState();
    const [endDate, setEndDate]=useState("");

    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [search, setSearch]=useState('');
    <Protected/>

        const [summarydata, setSummarydata]=useState([]);
        useEffect(()=>{
            async function Loadvotesummary(){ 
         await getData('votesummary').then((response) => { 
        response.data?setSummarydata(response.data):console.log(response.errors);
        })}
        async function Loadallvotes(){ 
            await getData('contestantvotes').then((response) => { 

      

                const graph_Labels = response.data.slice(0,5).map(table=>{
                    return table.nickname
                })
                const graph_Data = response.data.slice(0,5).map(table=>{
                    return table.votes
                })
                setPending(true);
            PopulateTable(response.data);
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
                

              response.errors && console.log(response.errors);
           })}
           Loadallvotes();
            Loadvotesummary();
            const interval=setInterval(()=>{ 
            Loadvotesummary();
            Loadallvotes();
           
            },10000);
            return ()=>{
              clearInterval(interval)
            }
           
    },[]);
    const handleSearch=async()=>{
        try {
            setPending(true)
            let newTable = await allTableData.filter(row=>{
             //   console.log('search',row.firstname.includes(search))
                return row.firstname.toLowerCase().includes(search.toLowerCase())||row.nickname.toLowerCase().includes(search.toLowerCase());
            })
            PopulateTable(newTable)
            setPending(false)
    
        } catch (error) {
            console.log(error)
        }
    }

   
    function PopulateTable(newTable)
{
    let searchData = newTable.map(table=>{
        let imageUrl=process.env.REACT_APP_BASE_URL;
        let profile="";
       imageUrl= imageUrl.substring(0, imageUrl.length-4);
        if(table.profile)
        {
         profile =imageUrl.concat(table.profile.substring(1));
  
//alert(profile);
}
    
        return {
         image:<div><img height="40" width="40" src={profile} alt="profile"/></div>,
       
            code:table.code,
            name: table.firstname,
            nickname:table.nickname,
            votes:table.votes?table.votes:0       
         }
    })
    setTableData(searchData);
}
 useEffect(()=>{
        handleSearch();
    },[search])
 
    const setstartdate = event => {

        setStartDate(event.target.value);
      };
      const setenddate = event => {
     
        setEndDate(event.target.value);
 
      };

      const handleFilter=()=>
      {
alert (startDate+" and "+endDate)
      }
      async function handleExport()
      {
        let endpoint="export"
        axios.get(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
            method: 'GET',
            headers: {
                //   'Content-Type': 'application/json',
                   'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
                 },
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        });
      }
    return (
        <div className={styles.maincontent}>
            <TitleRow>Export</TitleRow>
        <Row background={"white"}>
            <div className={styles.export}>
            <input type="date" name="start_date" onChange={setstartdate}/>
            <input type="date" name="end_date" onChange={setenddate}/>
            <Button value={"Filter"} onClick={handleFilter} height={30} width={140} background={"green"} color={"white"} fontsize={18}/>
            <Button value={"Export"} onClick={handleExport} height={30} width={140} background={"blue"} color={"white"} fontsize={18}/>
            </div>
        </Row>
  
        <Row background={"white"}><Tables columns={columns} data={tableData} paginate={true}/></Row>
         
        </div>
    )
}