import styles from "./layout.module.css"
import 'font-awesome/css/font-awesome.min.css';
import { MiniSidebar } from "./Sidenav";
import { useNavigate,Navigate } from "react-router-dom";




export default function Header(){
   const navigate=useNavigate();
   const onClick=()=>{
    sessionStorage.clear();
 navigate('/login', {replace: true});
 window.location.reload();
 //window.location.reload(true);
//    <Navigate replace to="/login" />
}
function handleClick(){
    navigate('/changepassword');
}
  
    return(
        <div className={styles.header}>
          <div>
          <MiniSidebar/>
          </div>
            <div className={styles.user}><div className={styles.userdetail}>
                <span className={styles.companyname}>{sessionStorage.getItem("username")}</span>
                <span className={styles.username}>{sessionStorage.getItem("role")} </span>
                </div>
            
                <div className={styles.logout}>
                {/* <button onClick={handleClick} style={{fontSize:10}}><i className="fa fa-key" aria-hidden="true"></i></button> */}
                <button onClick={onClick}><i class="fa fa-user-circle-o" ariel="hidden"></i></button>
                </div>
            </div>
        </div>
    )
}