import styles from "./rows.module.css"

export default function Row(props){
    const height=props.height?props.height:null;
   const  width=props.width?props.width:null;
   const  background=props.background?props.background:null;
    return(
        <div className={styles.row} style={{height:height, width:width, background:background}}>
            {props.children}
        </div>
    )
}