import styles from "../pages.module.css"
import Row from "../../Components/RowDivs/Row"
import Tables from "../../Components/Tables/Table"
import TitleRow from "../../Components/RowDivs/TitleRow"


const columns = [
    {
        name: 'Category',
        selector: row => row.category,
        sortable:true,
        headerStyle:{fontWeight:"bold",'whiteSpace': 'unset'},
     
    },
    {
        name: "Total",
        selector: row => row.total,
        sortable:true
    },
    {
        name: "Bought",
        selector: row => row.bought,
        sortable:true
    },
    {
        name: "Remaining",
        selector: row => row.remaining,
        sortable:true
    }
    // {
    //     cell: () => <button onClick={(e)=>alert(e.target)}>Action</button>,
    //     ignoreRowClick: false,
    //     allowOverflow: true,
    //     button: false
    // }
  ];
  const data=[
  
  {category:"VVIP", total:"2000", bought:"1000", remaining:"1000"},
  {category:"VIP", total:"2000", bought:"1000", remaining:"1000"},
  {category:"Regular", total:"2000", bought:"1000", remaining:"1000"},
  {category:"Total", total:"6000", bought:"3000", remaining:"3000"},
  ]

export default function Tickets(){
    return (
        <div className={styles.maincontent}>
<TitleRow>Tickets</TitleRow>
<Row><Tables data={data} columns={columns}/></Row>
        </div>
    )
}