
import InputForm from "../Components/Form/inputForm"
import styles from "./pages.module.css"
import swal from 'sweetalert';
import TitleRow from "../Components/RowDivs/TitleRow"
import Row from "../Components/RowDivs/Row"
import Protected from "../Components/Protected/Protected"


async function changePassword(credentials) {
  return fetch(`${process.env.REACT_APP_BASE_URL}changepassword`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }



export default function Changepassword(){

{/* <Protected/> */}
  async function Onsubmit(credential, e){
    e.preventDefault();  

    const response = await changePassword(credential);
  //console.log(sessionStorage.getItem('user'));
  //console.log(sessionStorage.getItem('user2'));
   if ( response.data) {
      swal("Success","Password Changed ", "success", {
        buttons: false,
        timer: 2000,
      });
    } else {
      swal("Failed", JSON.stringify(response.errors[0]), "error");
    }
  }
  
    const data=[
      {
        input:{
                type:"hidden" ,name:"id", value:sessionStorage.getItem('userid'),
                validation:{required:true}
              }
      },
     
      {
          input:{
                  type:"password", placeholder:"Enter Password",name:"password", value:"",
                  validation:{required:true,  maxLength:50 }
                }
        },
        {
          input:{
                  type:"password",name:"new_password", value:"", placeholder:"Re-enter Password",
                  validation:{required:true,  maxLength:50 }
                }
        },   
        {
          submit:{
                  type:"submit", value:"Change"
                }
        }
      ]
      return(
        <div className={styles.maincontent}>  <TitleRow>Change Password</TitleRow>
        <Row>
        <InputForm  data={data} onSubmit={Onsubmit} />
   

        </Row>
        </div>
  
    
      )
      
}
