import Tables from "../../Components/Tables/Table"
import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import Button from "../../Components/Buttons/Button"
import { useNavigate } from "react-router-dom"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"


async function getData(endpoint) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
      .then(data => data.json())
   }
const columns = [
    {
        name: "Firstname",
        selector: row => row.firstname,
        sortable:true
    },
    {
        name: "Lastname",
        selector: row => row.lastname,
        sortable:true
    },
    {
        name: "Email",
        selector: row => row.email,
        sortable:true
    },
    {
        name: 'Role',
        selector: row => row.role,
        sortable:true
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable:true
    },
    {
        name: 'Action',
        selector: row => row.action,
        sortable:true
    }
  
];

export default function Users(){
    // <Protected/>

    const [tableData,setTableData]= useState([]);
    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [search, setSearch]=useState('');
    const navigate = useNavigate();
  
    useEffect(()=>{
    async function Loadallusers(){ 
        await getData('users').then((response) => { 
            setPending(true);
            console.log(response.data);
            PopulateTable(response.data);
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
       })}
       Loadallusers(); 
      
},[]);

const handleSearch=async()=>{
    try {
        setPending(true)
        let newTable = await allTableData.filter(row=>{
         //   console.log('search',row.firstname.includes(search))
            return row.firstname.toLowerCase().includes(search.toLowerCase())||row.lastname.toLowerCase().includes(search.toLowerCase())||row.email.toLowerCase().includes(search.toLowerCase());
        })
        PopulateTable(newTable)
        setPending(false)

    } catch (error) {
        console.log(error)
    }
}

function onClick(rowdata)
{
navigate('/adduser',{state:{data:rowdata,new:false}});
}

function PopulateTable(newTable)
{
    let searchData = newTable.map(table=>{
        return {
            firstname:table.firstname,
            lastname:table.lastname, 
            email:table.email,
            role:table.role,
            status:table.active?"Active":"Disabled",
            action:<><button disabled style={{color:"green",background:"white", border:"none", cursor:"pointer"}} onClick={() => onClick(table)} > Edit
            </button><button disabled style={{color:"red",background:"white", border:"none", cursor:"pointer"}} onClick={() => onClick(table)} > Delete
            </button>
            </>
                                 
        }
    })
    setTableData(searchData);
}
 useEffect(()=>{
        handleSearch();
    },[search])
  useEffect(()=>{
        handleSearch();
    },[search])
function handleClick(){
    navigate('/adduser',{state:{new:true}});
}
    return (
        <div className={styles.maincontent}>
  <TitleRow>Users</TitleRow>
<Row height={20}>
  <div className={styles.actionbox}>
  <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} style={{background:"transparent"}}/>
  <Button onClick={handleClick} height={30} width={120} background={"rgb(155, 101, 96)"} color={"white"} value={"Add Users"}/>
  </div></Row>
      <Row>{pending?"Loading...":<Tables  progressPending={pending} data={tableData} columns={columns} paginate={true}/>}</Row>
        </div>
    )
}