import styles from "../pages.module.css"
import Row from "../../Components/RowDivs/Row"
import TitleRow from "../../Components/RowDivs/TitleRow";
import InputForm from "../../Components/Form/inputForm";
const data=[
    {
      input:{
              type:"text", placeholder:"Enter ticket code",name:"code", value:"",
              validation:{required:true,  maxLength:50, pattern:/[a-z0-9.-]/}
            }
    },
      
    {
      submit:{
              type:"submit", value:"Verify"
            }
    }
  ]

  function Onsubmit(data, e){
    e.preventDefault();
    alert("Invalid Code")
  
  }
export default function VerifyTickets(){
    return (
        <div className={styles.maincontent}>
            <TitleRow>Verify Tickets</TitleRow>
            <Row>
            <InputForm  data={data} onSubmit={Onsubmit}  />
            </Row>
            </div>
    )
}