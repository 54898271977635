import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {useLocation} from 'react-router-dom';
import Contestants from "./ContestantPage";
import { useEffect, useState } from "react";
import axios from 'axios';
import Rowcard from "../../Components/RowDivs/Rowcard";
import Button from "../../Components/Buttons/Button";

async function addData(method, userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify(userData)
  })
    .then(data => data.json())
 }

export default function ContestantCMS(){

const [facts, setFacts]=useState('');
const [videoInfo, setVideoInfo]=useState([]);
const [showVideo, setShowVideo]=useState([]);
const location =useLocation();
const content = location.state;


  const addvideolink=[
    {
      
      input:{
              type:"hidden",name:"contestant_code", value:content.data.code, 
              validation:{required:true,  maxLength:50}
            }
    }, 
    {
      
      input:{
              type:"text", placeholder:"Add Title",name:"title", 
              validation:{required:true,  maxLength:150}
            }
    },  {
      input:{
              type:"text", placeholder:"Add Episode",name:"episode", 
              validation:{required:true,  maxLength:50}
            }
    },
   
      {
      input:{
        type:"text", placeholder:"Add Link",name:"link",
        validation:{required:true,  maxLength:150}
      }
},

       
    {
      submit:{
              type:"submit", value:"Save"
            }
    }
  ];
 



useEffect(()=>{
 
async function LoadFacts()
{

const endpoint = `profile/${content.data.code}`;
const method='POST';
await addData(method, " ", endpoint).then((response) => { 
 console.log("entire response",response)

   if(response.data)
   { 
    setFacts(response.data.contestantprofile.fact);
    setVideoInfo(response.data.contestantvideo);
    setShowVideo(response.data.contestantvideo[0])
   }
  response.errors &&console.log(response.errors);
    
    });
}
LoadFacts();
   
},[]);

console.log('this data',showVideo.title);
async function SaveFacts()
{

const formDat = new FormData();
formDat.append('fact', facts);
const endpoint = `add_contestants_profile/${content.data.code}`;
const method='POST';
await addData(method, {"fact":facts}, endpoint).then((response) => { 
 console.log("entire response",response.data.contestantprofile)

   if( response.data)
   {
  Swal.fire({
   
  text:"Facts Saved",
    icon: 'success',
    timer: 5000,
  });
}
      
  response.errors && Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: JSON.stringify(response.errors[0]),
    timer:5000
   
  })
    
    });
}



async function SaveVideoLink(videoData, e){
  e.preventDefault(); 

const endpoint = "add_contestant_video";
const method="POST";
await addData(method, videoData, endpoint).then((response) => { 
 console.log("entire response",response.data)
    if(response.data)
    { 
  Swal.fire({
    title: "Saved",
    icon: 'success',
    timer: 5000,
  });

 setShowVideo(response.data)
}
  response.errors && Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: JSON.stringify(response.errors[0]),
    timer:5000
   
  })
    
    });
  }

    return (
        <div className={styles.maincontent}>  <TitleRow> Contestant CMS</TitleRow>
        <Row > 
        <div className={styles.cms_container}>
        <div className={styles.cms_left}>
 
<div className={styles.cms_images}>
<img className={styles.cms_images} src={content.profile} alt="contestant"/>
</div>
<div className={styles.cms_info}>
  <div>{content.data.nickname}</div>
  <div>{content.data.code}</div>
  
  </div>
  <div className={styles.cms_fact}>
  <TitleRow> Fact</TitleRow>
<textarea maxlength="50" name="contestant_cms" onChange={(e)=>setFacts(e.target.value)} value={facts}>


</textarea>
<Button value={"Save"} onClick={SaveFacts} background={"rgb(50, 52, 55)"} color={"white"} height={30} width={100}/>

  </div>
 
        </div>
        <div className={styles.cms_middle}>
        <div className={styles.cms_video_player}>
        <iframe width={'100%'} height={'100%'} src={showVideo.link} 
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen="allowFullScreen"></iframe>
</div>
<div className={styles.cms_video_info}>
  <div>{showVideo.title}</div>
  </div>
  <div className={styles.cms_save_video_link}>
  <TitleRow> Save Link</TitleRow>
  <InputForm  data={addvideolink} onSubmit={SaveVideoLink} />

  </div>

        </div>
        <div className={styles.cms_right}>

        {videoInfo.map((video, i) => {     
        //   console.log("Entered");                 
           // Return the element. Also pass key     
           return (

        <div className={styles.cms_video} onClick={()=>setShowVideo(video)}>
        <div className={styles.cms_video_thumbnail}><img className={styles.cms_thumbnail} src={content.profile} alt="contestant"/></div>
        <div className={styles.cms_video_title}>{video.title}</div>
        </div>

) 
})}
         </div>
        </div>
     
        </Row>
        
        </div>
    )
}