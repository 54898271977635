import DataTable from 'react-data-table-component'
//import "react-table/react-table.css";  
import styles from "./table.module.css"


 

       export default function Tables({columns, data, paginate, progressPending})
       {
        return (  
          <div className={styles.tables}>
               <DataTable 
               progressPending={progressPending}
                   data={data}  
                   columns={columns}  
        pagination={paginate}
              //     defaultPageSize = {2}  
                //   pageSizeOptions = {[2,4, 6]}  
                />  
                </div>
                
       )  
       }