import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {useLocation} from 'react-router-dom';

async function deleteUser(userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify(userData)
  })
    .then(data => data.json())
 }

export default function DeleteUser(){

  const location =useLocation();
  const content = location.state;
  //console.log(location.state.data)


  async function OnClick(userData, e){
    e.preventDefault(); 
  //console.log(userData);
  const endpoint ="deleteuser";
  await deleteUser(userData, endpoint).then((response) => { 
      response.data&& 
    Swal.fire({
      title: content.new?'New User Added':"User Updated",
      html:`<b>Firstname:</b> ${response.data.firstname}<br> <b>Lastname:</b> ${response.data.lastname} <br>
      ${response.data.email}<br> <b>Role:</b> ${response.data.role}`,
      icon: 'success',
      timer: 5000,
    });
        
    response.errors && Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: JSON.stringify(response.errors[0]),
      timer:5000
     
    })
      
      });
    }
    return (
        <div className={styles.maincontent}>  <TitleRow>Delete User</TitleRow>
        <Row>
<div>

  Do you want to go ahead and delete {content.data.firstname} {content.data.lastname}?

</div>
<div>
  <button onClick={OnClick}>Delete</button>
</div>


        </Row>
        </div>
    )
}