import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {useLocation} from 'react-router-dom';
import Contestants from "./SeasonPage";
import { useEffect, useState } from "react";
import axios from 'axios';

async function addUser(userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify(userData)
  })
    .then(data => data.json())
 }

export default function AddNewSeason(){
  const [file, setFile] = useState()
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const location =useLocation();
  const content = location.state;
  console.log(location.state.data)

  const data=[
  
    {
      input:{label:"Name",
              type:"text", placeholder:"Enter Season Name",name:"name", value:content.new?"":content.data.name,
              validation:{required:true,  maxLength:50, pattern:/[a-zA-Z]/}
            }
    },
   
      {
      input:{label:"Start Date",
        type:"date", placeholder:"Start Date",name:"start_date", value:content.new?"":content.data.start_date,
        validation:{required:true}
      }
},
{
  select:{
          type:"active",name:"active", defaultvalue:content.new?"":content.data.active,
          validation:{required:true,  Min:8 },
          options:[ 
                    {value:1, label:"Active"},
                    {value:0, label:"Inactive"},      
                 ],
              //   defaultvalue:true           
        }
},
       
    {
      submit:{
              type:"submit", value:content.new?"Add":"Update"
            }
    }
  ];
 

  async function Onsubmit(userData, e){
    e.preventDefault(); 
  //console.log(userData);
  const endpoint = content.new?"add_season":`update_season/${content.data.id}`;
  await addUser(userData, endpoint).then((response) => { 
   console.log("entire response",response)
   file&&response.data&& handleProfileSubmit(response.data.id)
      response.data&& 
    Swal.fire({
      title: content.new?'New Season Added':"Season Updated",
      html:`<b>Name:</b> ${response.data.name}<br> <b>Start Date:</b> ${response.data.start_date} <br>
     <b>Active:</b> ${(response.data.active==1)?true:false}`,
      icon: 'success',
      timer: 5000,
    });
        
    response.errors && Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: JSON.stringify(response.errors[0]),
      timer:5000
     
    })
      
      });
    }
 
    function handleChange(event) {
      

      if (!event.target.files[0]|| event.target.files[0].length === 0) {
        setSelectedFile(undefined)
        return
    }else{
      setFile(event.target.files[0]);
      const objectUrl = URL.createObjectURL(event.target.files[0])
      setPreview(objectUrl)
  
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)

    }
     // setSelectedFile(event.target.files[0]);
  //    handleProfileSubmit();
    }
  //   useEffect(() => {
  //     if (!file) {
  //         setPreview(undefined)
  //         return
  //     }
  
  //     const objectUrl = URL.createObjectURL(file)
  //     setPreview(objectUrl)
  
  //     // free memory when ever this component is unmounted
  //     return () => URL.revokeObjectURL(objectUrl)
  // }, [file])
  function handleProfileSubmit(id) {
    //  event.preventDefault()
      const url = `${process.env.REACT_APP_BASE_URL}logoupload`;
    //  console.log("this is what i want",id);
      const formDat = new FormData();
      formDat.append('logo', file);
     formDat.append('id', id);
    // console.log(formDat);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
        },
      };
      axios.post(url, formDat, config).then((response) => {
        console.log(response.data);
      });
  
    }
    return (
        <div className={styles.maincontent}>  <TitleRow>Season</TitleRow>
        <Row>  <div>
       <div style={{height:120, width:120, textAlign:"center", border:"1px solid grey"}}><img height="120" width="120" src={content.logo?preview?preview:content.logo:preview} alt="logo"/></div> 
             <input type="file" refs="myForm" onChange={(event)=>handleChange(event)}/>
       </div></Row>
        <Row>
        <InputForm  data={data} onSubmit={Onsubmit} />
   

        </Row>
        </div>
    )
}