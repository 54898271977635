import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {Navigate, useLocation} from 'react-router-dom';
import Contestants from "./SeasonPage";
import { useEffect, useState } from "react";
import axios from 'axios';
import Button from "../../Components/Buttons/Button";
import { useNavigate } from "react-router-dom"

async function deleteUser(userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify({
     
      id: userData
   }),
  })
    .then(data => data.json())
 }

export default function DeleteSeason(){
  const [file, setFile] = useState()
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const location =useLocation();
  const content = location.state;
  const navigate = useNavigate();
  //console.log(location.state.data)



  async function OnClick(){
   
  //console.log(userData);
  const endpoint = "delete_season";
  await deleteUser(content.data.id, endpoint).then((response) => { 
   
    response.data&& 
    Swal.fire({
  
      html: response.data,
      icon: 'success',
      timer: 5000,
    });
    response.data&&  goBack();    
    response.errors && Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: JSON.stringify(response.errors[0]),
      timer:5000
     
    })
      
      });
    }
 
    function goBack(){
      navigate('/seasons',{state:{new:true}});
  }

    return (
        <div className={styles.maincontent}>  <TitleRow>Delete Seasons</TitleRow>
        <Row>  <div>
       <div style={{height:120, width:120, textAlign:"center", border:"1px solid grey"}}><img height="120" width="120" src={content.logo?preview?preview:content.logo:preview} alt="logo"/></div> 
          
       </div></Row>
        <Row>
        Do you want to go ahead and delete {content.data.name}?
        </Row>
        <Row><Button height={30} width={130} color={'white'} background={'red'} value={"Delete"} onClick={OnClick}></Button></Row>
        </div>
    )
}