

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar} from 'react-chartjs-2';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



  
export default function Graphs(props) {
 

  const data={
    labels: props.label,
    datasets: [
      {
        label: "Applicants",
        data: props.data,
        backgroundColor: "rgb(90, 197, 200)",
        borderColor: "rgb(47, 167, 177)",
        borderWidth: 1,
        responsive: true,
        maintainAspectRatio: false,
      },
    ],
  }

  const options = {
    plugins:{
      legend:{display:false},
    
       title:{
      display:true,
      text:'Applicants',
      fullsize:true,
      font:{weight:'bolder', size:'16'}, 
      color:"rgb(168, 76, 22)"   
    }
    }
   ,
    scales: {
      x: {
          title: {
            color:"rgb(168, 76, 22)",  
          display: true,
          text: 'Applicants'
          }
      },
      y: {
          title: {
            color:"rgb(168, 76, 22)"  ,
          display: true,
          text: 'Applicants'
          }
      }
      },
      maintainAspectRatio: false
    };


  return (
    <div style={{width: '99%', height:'250px'}} >
<Bar data={data} options={options}/>
    </div>
  );
}
