import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {Navigate, useLocation} from 'react-router-dom';
import Contestants from "./ContestantPage";
import { useEffect, useState } from "react";
import axios from 'axios';
import Button from "../../Components/Buttons/Button";
import { useNavigate } from "react-router-dom"

async function evectContestant(userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}/${userData}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },

  })
    .then(data => data.json())
 }

export default function DeleteContestant(){
  const [file, setFile] = useState()
  const [preview, setPreview] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const location =useLocation();
  const content = location.state;
  const navigate = useNavigate();
  //console.log(location.state.data)



  async function OnClick(){
   
  //console.log(userData);
  // const endpoint = "deletecontestant";
  const endpoint = "evict-contestant";
  alert(endpoint);
  await evectContestant(content.data.id, endpoint).then((response) => { 
    response.data&& 
    Swal.fire({
  
      html: response.data,
      icon: 'success',
      timer: 5000,
    });
    response.data&&  goBack();    
    response.errors && Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: JSON.stringify(response.errors[0]),
      timer:5000
     
    })
      
      });
    }
 
    function goBack(){
      navigate('/qualified_contestants',{state:{new:true}});
  }

    return (
        <div className={styles.maincontent}>  <TitleRow>Evict Contestant</TitleRow>
        <Row>  <div>
       <div style={{height:120, width:120, textAlign:"center", border:"1px solid grey"}}><img height="120" width="120" src={content.profile?preview?preview:content.profile:preview} alt="logo"/></div> 
          
       </div></Row>
        <Row>
      <span style={{color:"rgb(128,0,128)"}}>  Do you want to go ahead and Evict <span style={{color:"rgb(128,0,0)",fontWeight:600,fontSize:20}}>{content.data.name}</span>, Code: <span style={{color:"rgb(128,0,0)",fontWeight:600,fontSize:20}}>{content.data.code}?</span></span>
  
        </Row>
        <Row><Button height={30} width={130} color={'white'} background={'rgb(128,0,0)'} value={"Evict"} onClick={OnClick}></Button></Row>
        </div>
    )
}