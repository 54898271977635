import styles from "../pages.module.css"
import Row from "../../Components/RowDivs/Row"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Graphs from "../../Components/Graph/Graph"
import Tables from "../../Components/Tables/Table"


const columns = [
    {
        name: 'Ticket Number',
        selector: row => row.ticketnumber,
        sortable:true,
        headerStyle:{fontWeight:"bold",'whiteSpace': 'unset'},
     
    },
    {
        name: "Ticket Type",
        selector: row => row.tickettype,
        sortable:true
    },
    {
        name: "Payer's Name",
        selector: row => row.payername,
        sortable:true
    },
    {
        name: "Payer's Contact",
        selector: row => row.payercontact,
        sortable:true
    },
    {
        name: 'Purchase Date',
        selector: row => row.purchasedate,
        sortable:true
    }
    // {
    //     cell: () => <button onClick={(e)=>alert(e.target)}>Action</button>,
    //     ignoreRowClick: false,
    //     allowOverflow: true,
    //     button: false
    // }
];
const data=[

{ticketnumber:"1122134234",  ticketetype:"VVIP", payername:"Eugene Siaw", payercontact:"0240945395", purchasedate:"2022-08-23 09:28:54"},
{ticketnumber:"1122134234",  ticketetype:"VVIP", payername:"Eugene Siaw", payercontact:"0240945395", purchasedate:"2022-08-23 09:28:54"},
{ticketnumber:"1122134234",  ticketetype:"VVIP", payername:"Eugene Siaw", payercontact:"0240945395", purchasedate:"2022-08-23 09:28:54"},
{ticketnumber:"1122134234",  ticketetype:"VVIP", payername:"Eugene Siaw", payercontact:"0240945395", purchasedate:"2022-08-23 09:28:54"},
{ticketnumber:"1122134234",  ticketetype:"VVIP", payername:"Eugene Siaw", payercontact:"0240945395", purchasedate:"2022-08-23 09:28:54"},
]
export default function PurchasedTickets(){
    return (
        <div className={styles.maincontent}>
            <TitleRow>Purchased Tickets</TitleRow>
        <Row background={"white"}><Graphs/></Row>
        <Row height={20}>
  <div className={styles.actionbox}>
  <input type="text" placeholder="Search" style={{background:"transparent"}}/>
  </div></Row>
        <Row background={"white"}><Tables columns={columns} data={data}/></Row>
         
        </div>
    )
}