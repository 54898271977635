import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {useLocation} from 'react-router-dom';
import Contestants from "./SeasonPage";
import { useEffect, useState } from "react";
import axios from 'axios';
import Rowcard from "../../Components/RowDivs/Rowcard";
import Button from "../../Components/Buttons/Button";
import Streaming from "../../Components/signalStreaming"
async function addData(method, userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify(userData)
  })
    .then(data => data.json())
 }
 function videostat()
 {
   alert("working")
 }
export default function ContestantCMS(){


const [videoInfo, setVideoInfo]=useState([]);
const [showVideo, setShowVideo]=useState([]);
const [liveStatus, setLiveStatus]=useState(false);
const location =useLocation();
const content = location.state;




  const addvideolink=[
    {
      
      input:{
              type:"hidden",name:"season_id", value:content.data.id, 
              validation:{required:true,  maxLength:150}
            }
    }, 
    {
      
      input:{
              type:"text", placeholder:"Add Title",name:"title", 
              validation:{required:true,  maxLength:150}
            }
    },  {
      input:{
              type:"text", placeholder:"Add Episode",name:"episode", 
              validation:{required:true,  maxLength:50}
            }
    },
   
      {
      input:{
        type:"text", placeholder:"Add Link",name:"link",
        validation:{required:true,  maxLength:150}
      }
},

       
    {
      submit:{
              type:"submit", value:"Save"
            }
    }
  ];
 



useEffect(()=>{
 
async function LoadFacts()
{

const endpoint = `season_video/${content.data.id}`;
const method='POST';
await addData(method, " ", endpoint).then((response) => { 
// console.log("entire response",response)

   if(response.data)
   { 
    setVideoInfo(response.data.seasonvideo);
    setShowVideo(response.data.seasonvideo[0]);
    setLiveStatus(response.data.seasonvideo[0].live)
   }
  response.errors &&console.log(response.errors);
    
    });
}
LoadFacts();
   
},[]);

//console.log('this data',showVideo.title);




async function SaveVideoLink(videoData, e){
  e.preventDefault(); 

const endpoint = "add_season_video";
const method="POST";
await addData(method, videoData, endpoint).then((response) => { 
 //console.log("entire response",response)
    if(response.data)
    { 
  Swal.fire({
    title: "Saved",
    icon: 'success',
    timer: 5000,
  });

 setShowVideo(response.data)
 setShowVideo(response.data.live)
}
  response.errors && Swal.fire({
    icon: 'error',
    title: 'Oops...',
    text: JSON.stringify(response.errors[0]),
    timer:5000
   
  })
    
    });
  }

async function changeLiveStatus(id, liveState)
{

  
const endpoint = `update_season_video/${id}`;
const method="POST";
await addData(method, {live:liveState}, endpoint).then((response) => { 
 //console.log("entire response",response)
    if(response.data)
    { 
  Swal.fire({
   // title: "Current video gone live on Diasa Website",
    icon: 'success',
    timer: 5000,
  });
  setLiveStatus(response.data.live)
}
})
}

function setCurrentVideo(video, live)
{
//  console.log("this is what i want", live)
  setShowVideo(video);
  setLiveStatus(video.live)
}

    return (
        <div className={styles.maincontent}>  <TitleRow> Season CMS</TitleRow>
        <Row > 
        <div className={styles.cms_container}>
        <div className={styles.cms_left}>
 
<div className={styles.cms_images}>
<img className={styles.cms_images} src={content.logo} alt="season"/>
</div>
<div className={styles.cms_info}>
  <div>{content.data.name}</div>
  <div>{content.data.start_date}</div>
  
  </div>
 
 
        </div>
        <div className={styles.cms_middle}>
          <div   className={styles.cms_video_stream} onClick={()=>changeLiveStatus(showVideo.id, liveStatus)}><Streaming live={liveStatus}/></div>
        <div className={styles.cms_video_player}>
        <iframe width={'100%'} height={'100%'} src={showVideo.link} 
        title="YouTube video player" frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen="allowFullScreen"></iframe>
</div>
<div className={styles.cms_video_info}>
  <div>{showVideo.title}</div>
  </div>
  <div className={styles.cms_save_video_link}>
  <TitleRow> Save Link</TitleRow>
  <InputForm  data={addvideolink} onSubmit={SaveVideoLink} />

  </div>

        </div>
        <div className={styles.cms_right}>

        {videoInfo.map((video, i) => {     
       //    console.log("Entered");                 
           // Return the element. Also pass key     
           return (
        <div className={styles.cms_video} onClick={()=>setCurrentVideo(video, video.live)}>
        <div className={styles.cms_video_thumbnail}><img className={styles.cms_thumbnail} src={content.logo} alt="contestant"/></div>
        <div className={styles.cms_video_title}>{video.title}</div>
        </div>
) 
})}
         </div>
        </div>
     
        </Row>
        
        </div>
    )
}