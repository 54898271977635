import Tables from "../../Components/Tables/Table"
import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import Button from "../../Components/Buttons/Button"
import { useNavigate } from "react-router-dom"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"
import Swal from 'sweetalert2'


async function getData(endpoint) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
      .then(data => data.json())
   }

  
const columns = [
    // {
    //     name: "Image",
    //     selector: row => row.image,
    //     sortable:true
       
    // },
    {
        name: "Comments",
        selector: row => row.comment,
        sortable:true
       
    },
    {
        name: "Rates",
        selector: row => row.rate,
        sortable:true
       
    },
    {
        name: "",
        selector: row => row.time,
        sortable:true
       
    },
 
   
  
  
];

export default function Comments(){
    <Protected/>

    const [tableData,setTableData]= useState([]);
    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [search, setSearch]=useState('');
    const navigate = useNavigate();
    const role =sessionStorage.getItem('role');
    
  
    useEffect(()=>{
    async function Loadallusers(){ 
        await getData('comments').then((response) => { 
         console.log(response.data);
            setPending(true);
            PopulateTable(response.data);
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
       })}
       Loadallusers(); 
      
},[]);

const handleSearch=async()=>{
    try {

        setPending(true)
        let newTable = await allTableData.filter(row=>{
         //   console.log('search',row.firstname.includes(search))
            return row.rate.toLowerCase().includes(search.toLowerCase())||row.comments.toLowerCase().includes(search.toLowerCase());
        })
        PopulateTable(newTable)
        setPending(false)

    } catch (error) {
        console.log(error)
    }
}



function PopulateTable(newTable)
{
   
    let searchData = newTable.map(table=>{

      
        return {
        //   image:<div><img height="40" width="40" src={profile} alt="profile"/></div>,
         

            comment:table.comments,
            rate:table.rate,
            time:table.created_at,
         
                                 
        }
    })
    setTableData(searchData);
}
 useEffect(()=>{
        handleSearch();
    },[search])
  useEffect(()=>{
        handleSearch();
    },[search])
function handleClick(){
    navigate('/addcontestant',{state:{new:true}});
}
function onClick(rowdata,profile)
{
  
navigate('/addcontestant',{state:{data:rowdata, profile:profile, new:false}});
}
function Details(details)
{
    Swal.fire({
      
        html: JSON.stringify(details),
     
      })

}
function onDelete(rowdata, profile)
{

    navigate('/deletecontestant',{state:{data:rowdata, profile:profile, new:false}});
}
function CMS(rowdata, profile)
{

    navigate('/contestant_cms',{state:{data:rowdata, profile:profile, new:false}});
}

let add_user_button="";
if(role==="admin")
{
add_user_button=<> 
<Button onClick={handleClick} height={30} width={120} background={"rgb(155, 101, 96)"} color={"white"} value={"Add Contestant"}/>
</>
}
    return (
        <div className={styles.maincontent}>
          
  <TitleRow>Comments</TitleRow>
<Row height={20}>
  <div className={styles.actionbox}>
  <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} style={{background:"transparent"}}/>
  {/* {add_user_button} */}
  </div></Row>
      <Row>{pending?"Loading...":<Tables  progressPending={pending} data={tableData} columns={columns} paginate={true}/>}</Row>
        </div>
    )
}